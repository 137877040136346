import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/veterans.scss"
import ImageSlider from "../components/ImageSlider/ImageSlider"

const SmileForVeteransPage = () => {
  return (
    <Layout language="en">
      <SEO
        title="Smiles For Veterans Program"
        description="Smiles For Veterans Program at The Regional Center for Dental Implants & Oral Surgery"
      />
      <div className="sfv">
        <div className="sfv__hero sfv__section">
          <img
            className="sfv__hero-img--desktop"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smiles-for-veterans-desktop-hero-image"
            alt="smiles for soliders banner"
          />
          <img
            className="sfv__hero-img--mobile"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smiles-for-veterans-mobile-hero-image"
            alt="smiles for soliders banner"
          />
        </div>

        <div className="sfv__body sfv__section">
          <div className="sfv__container small">
            <h1>Meet Donald, Our First Smiles For Veterans Recipient!</h1>

            <p>
              We are thrilled to introduce Donald, an incredibly resilient
              43-year-old Navy Veteran hailing from Erwin, TN, as The Regional
              Center for Dental Implants & Oral Surgery's first-ever Smile For
              Veterans recipient!
            </p>
            <p>
              Donald's journey has been one of unwavering courage, having
              navigated significant challenges, particularly in the realm of
              dental health. In his own heartfelt words, he's battled feelings
              of embarrassment and a lack of confidence due to broken and
              decaying teeth. We are truly honored to be part of Donald's
              transformative journey, eagerly anticipating not only a remarkable
              change in his smile but also an uplifting enhancement in his
              overall quality of life.
            </p>
            <p>
              Teaming up with the talented restorative dentist, Dr. Amy Moody,
              The Regional Center for Dental Implants & Oral Surgery is
              committed to gifting Donald the radiant smile he undeniably
              deserves. We can't wait to stand by Donald's side as he
              confidently faces the world with his newfound joy and
              self-assurance.
            </p>
          </div>
        </div>

        <div className="sfv__section">
          <div className="sfv__container">
            <ImageSlider
              adaptiveHeight
              useDots
              images={[
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-1",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-2",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-3",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-4"
              ]}
            />
          </div>
        </div>

        <div className="sfv__section">
          <div className="sfv__container small">
            <p>
              Follow Donald’s journey on our{" "}
              <a
                href="https://www.facebook.com/regionalcenterforoms/"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>
              , and{" "}
              <a
                href="https://www.instagram.com/regionalcenterforoms/"
                title="Follow us on Instagram"
                target="_blank">
                Instagram
              </a>{" "}
              pages!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/procedures/dental-implants-johnson-city-tn/"
                  title="Learn more about dental implants">
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/procedures/full-arch-restoration-johnson-city-tn/"
                  title="Learn more about full-arch-restoration">
                  full-arch restoration
                </Link>{" "}
                to see how they can improve your oral health and confidence.
                Whether you need to replace one tooth or an entire arch of
                teeth, our team has a solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SmileForVeteransPage
